<template>
  <div
    class="tab-pane fade"
    id="pills-accountMapping"
    role="tabpanel"
    aria-labelledby="pills-accountMapping-tab"
  >
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-3 pb-5">
      <div class="col-3">
        <button
          class="btn download-box"
          style="background: #e9e7e7; color: #000000"
          @click="goPrevious"
        >
          <img
            src="@/assets/img/arrow-left-circle-fill.svg"
            alt="delete"
            srcset=""
          />
          Previous Page
        </button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-5 justify-content-end">
        <div class="form__item">
          <label class="form__label">Select a product</label>
          <multiselect
            v-model="product"
            id="productName"
            label="productName"
            track-by="productName"
            placeholder="Type to search product"
            open-direction="bottom"
            :options="allProducts"
            :multiple="false"
            :searchable="true"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="800"
            :show-no-results="false"
            :hide-selected="false"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option.productName }}</span>
                <span class="custom__remove" @click="remove(option)"
                  >❌</span
                >
              </span>
            </template>
          </multiselect>
        </div>
      </div>
    </div>

    <div class="ma-table">
      <div class="ma-thead">
        <div
          v-for="(text, i) in thead_texts"
          :key="i"
          :style="
            i == 0
              ? 'width: 10%;'
              : '' || i == thead_texts.length - 1
              ? 'width: 15%;'
              : ''
          "
          class="thead-child"
        >
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />
      <div v-if="mapDatasets.length > 0">
        <div v-for="(item, i) in mapDatasets" :key="i">
          <div class="ma-tbody">
            <div style="width: 10%" class="tbody-child">
              {{ item.id }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{ item.name }}
            </div>
            <!-- <div class="tbody-child" style="word-break: break-word">
              {{ getAllProductForDataset(item) }}
            </div> -->
            <div class="tbody-child" style="word-break: break-word">
              {{ item.description.replace(/_/g, " ") }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              <ul
                v-for="acc in getAllMappedAccountForAParameter(item)"
                :key="acc.request_key"
              >
                <li>{{ acc.account_name }}</li>
              </ul>
            </div>
            <div class="tbody-child dropdown" style="width: 15%">
              <button
                class="btn dropdown-toggle action"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#editMappingModal"
                  @click="openMappingModal(item)"
                >
                  Account Mapping
                </a>
                <!-- <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#editMappingModal"
                  @click="openEditMappingModal(item)"
                >
                  Edit Mapped Account
                </a> -->
                <!-- <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#editMappingModal"
                  @click="openDeletedMappedAccount(item)"
                >
                  Unmap Account
                </a> -->
              </div>
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>
      </div>

      <div v-else-if="setLoading && !sageTransactionsData.data">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editMappingModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-if="showDatasetModal"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                id="mappingCloseButton"
                ref="mappingCloseButton"
                data-dismiss="modal"
                @click="clearMapping"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Account Mapping</h5>
          </div>
          <div class="modal__body">
            <form @submit.prevent="mapAccount">
              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccgeneral"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Map Account To Dataset
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="ccgeneral" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div
                        class="row"
                        v-for="(accItem, i) in accountLists"
                        :key="i"
                      >
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Name</label>
                            <select
                              class="select--lg w-100"
                              id="form__label"
                              v-model="accItem.account_name"
                              @change="assignAccNumber(i)"
                            >
                              <option disabled value="null">
                                Select Account
                              </option>
                              <option
                                v-for="item in allAccounts"
                                :key="item.request_key"
                                :value="item.request_key"
                              >
                                {{ item.account_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Number</label>
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Account Number"
                              disabled
                              v-model="accItem.account_number"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Account Type</label>
                            <select
                              class="select--lg w-100"
                              id="form__label"
                              v-model="accItem.is_debit_account"
                            >
                              <option disabled value="null">
                                Select Account Type
                              </option>
                              <option
                                v-for="item in accountTypes"
                                :key="item.id"
                                :value="item.value"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-3" style="margin-top: 38px">
                          <div class="form__item">
                            <button
                              v-on:click="deleteAccount(i)"
                              type="button"
                              class="button page__body__header__button button--grey text--capital"
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                              <img
                                src="../../../assets/img/delete.svg"
                                alt=""
                                srcset=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="text-right mt-5 mb-5">
                        <button
                          @click="addAccount"
                          type="button"
                          class="d-flex align-items-center ml-auto button button--grey text--600 text--black text--small text--capital"
                        >
                          Add account
                          <ion-icon
                            name="add-outline"
                            class="text--600 text--medium ml-2"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#ccdetails"
                    aria-expanded="false"
                    aria-controls="ccdetails"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      View/Unmap Account From Dataset
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="ccdetails" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div
                        v-show="getSingleAccountData.length"
                        v-for="(accItem, i) in getSingleAccountData"
                        :key="i"
                      >
                        <div class="row mb-5" >
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Name</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Account Number"
                                disabled
                                v-model="accItem.account_name"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Number</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Account Number"
                                disabled
                                v-model="accItem.account_number"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Type</label>
                              <select
                                class="select--lg w-100"
                                id="form__label"
                                disabled
                                v-model="accItem.is_debit_account"
                              >
                                <option disabled value="null">
                                  Select Account Type
                                </option>
                                <option
                                  v-for="item in accountTypes"
                                  :key="item.id"
                                  :value="item.value"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Product Name</label>
                              <div class="input form__input form__input--lg">
                                {{ getProductName(accItem.product_id) }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-12"
                            style="display: flex; justify-content: flex-end"
                          >
                            <div class="form__item">
                              <button
                                v-on:click="showConfirmDeleteModal(accItem)"
                                type="button"
                                class="button page__body__header__button button--grey text--capital"
                              >
                                <span class="d-inline-flex mr-2">Unmap</span>
                                <img
                                  src="../../../assets/img/delete.svg"
                                  alt=""
                                  srcset=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" v-show="!getSingleAccountData.length">
                        <div class="col-12">
                          <div class="form__item">
                            <div
                              class="d-flex flex-column align-items-center justify-content-center p5 mt-5"
                            >
                              <img
                                src="@/assets/img/empty.svg"
                                alt="delete"
                                srcset=""
                              />

                              <span
                                class="d-block text--danger text--600 text--medium mt-3 mb-5"
                              >
                                No mapped account for this parameter</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!loadingPostDataSet"
                  >
                    Upload mapping
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                    v-if="loadingPostDataSet"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    v-if="!loadingPostDataSet"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearMapping"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="showDeletedMappedAccModal"
      @close="closeDeletedMappedAccount"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unmap Account</h5>
            <p>Please confirm that you want to unmap this account</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeletedMappedAccount"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="loadingUnMap"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="unMapAccount"
                v-else
              >
                confirm
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </div>
</template>
<script>
import moment from "moment";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import {
  POST_DATASET_MAPPING,
  DELETE_DATASET_MAPPING,
} from "@/core/services/store/coreBanking.module";
import Multiselect from "vue-multiselect";

export default {
  name: "MapAccount",
  props: [
    "mapDatasets",
    "loading",
    "allAccounts",
    "loadingPostDataSet",
    "allMappedAccounts",
    "allProducts",
    "prodId",
  ],
  data() {
    return {
      thead_texts: [
        "Dataset",
        "Name",
        // "Product Name",
        "Description",
        "Mapped Accounts",
        "Action",
      ],
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      parameters: {
        pageindex: 1,
        pagesize: 20,
        SortDescending: true,
        status: null,
        CreationDateFrom: null,
        CreationDateTo: null,
        SortColumn: null,
      },
      dataset: null,
      loadingUnMap: false,
      accountLists: [
        { account_number: "", account_name: null, is_debit_account: null },
      ],
      accountTypes: [
        { id: 1, name: "Credit", value: false },
        { id: 2, name: "Debit", value: true },
      ],
      showDeletedMappedAccModal: false,
      getSingleAccountData: [],
      requestKey: "",
      product: null,
      showDatasetModal: false,
    };
  },
  components: {
    Multiselect,
    DeleteModal,
  },
  watch: {
    prodId(newVal) {
      if (newVal) {
        this.product = this.allProducts.find(
          (y) => y.id === newVal || y.loanProductKey === newVal
        );
      }
    },
  },
  methods: {
    goPrevious() {
      this.$emit("showConfiguredProduct");
    },

    deleteAccount(idx) {
      if (this.accountLists.length > 1) {
        this.accountLists.splice(idx, 1);
      } else {
        this.alertWarning(
          "At least one account item must be available for submit"
        );
      }
    },

    getAllMappedAccountForAParameter(data) {
      let findAccount = [];

      if (this.product) {
        findAccount = this.allMappedAccounts.filter(
          (acc) => acc.dataset === data.id &&
            (acc.product_id === this.product.id || acc.product_id === this.product.loanProductKey)
        );
      } else {
        findAccount = this.allMappedAccounts.filter(
          (acc) => acc.dataset === data.id
        );
      }

      return findAccount;
    },

    openDeletedMappedAccount(data) {
      this.dataset = data.id;
      const findAccount = this.allMappedAccounts.filter(
        (acc) => acc.dataset === this.dataset
      );
      this.getSingleAccountData = findAccount;
    },

    showConfirmDeleteModal(item) {
      this.showDeletedMappedAccModal = true;
      this.requestKey = item.request_key;
    },

    closeDeletedMappedAccount() {
      this.showDeletedMappedAccModal = false;
    },

    clearMapping() {
      this.showDatasetModal = false;
      this.dataset = null;
      this.getSingleAccountData = [];
      this.accountLists = [
        { account_number: "", account_name: null, is_debit_account: null },
      ];
    },

    assignAccNumber(i) {
      // const findAccount = this.allMappedAccounts
      //   .filter((acc) => acc.dataset === this.dataset)
      //   .map((acc) => acc.account_request_key);
      const key = this.accountLists[i].account_name;
      // if (!findAccount.includes(key)) {
      this.accountLists[i].account_number = this.allAccounts.find(
        (acc) => acc.request_key === key
      )?.account_number;
      // } else {
      //   this.accountLists[i].account_name = null;
      //   this.accountLists[i].is_debit_account = null;
      //   this.alertWarning(
      //     "This account has already been mapped to the selected dataset with another product."
      //   );
      // }
    },

    mapAccount() {
      let datasetAccountMappingCreateRequest = [];
      this.accountLists.forEach((item) => {
        if (
          item.account_number &&
          item.account_name &&
          (item.is_debit_account === true || item.is_debit_account === false)
        ) {
          datasetAccountMappingCreateRequest.push({
            dataset: this.dataset,
            account_request_key: item.account_name,
            is_debit_account: item.is_debit_account,
            product_id: this.product.id || this.product.loanProductKey,
          });
        }
      });
      if (datasetAccountMappingCreateRequest.length) {
        let form = { datasetAccountMappingCreateRequest };
        this.$store.dispatch(POST_DATASET_MAPPING, form).then(() => {
          this.alertSuccess("Account mapping is successful.");
          this.$emit("accountMapped");
          this.clearMapping();
          document.getElementById("editMappingModal").click();
          this.dataset = null;
          // this.product = null;
        });
      } else {
        this.alertWarning(
          `No account detail values was found when trying to map this dataset
          or the account details entered are incomplete.
          Please note an account without complete data will not be submitted.`
        );
      }
    },

    unMapAccount() {
      this.loadingUnMap = true;
      this.$store.dispatch(DELETE_DATASET_MAPPING, this.requestKey).then(() => {
        this.alertSuccess("Account was successfully unmapped.");
        this.$emit("accountMapped");
        this.loadingUnMap = false;
        this.getSingleAccountData = this.getSingleAccountData.filter(
          (item) => item.request_key !== this.requestKey
        );
        this.closeDeletedMappedAccount();
      });
    },

    addAccount() {
      this.accountLists.push({
        account_number: "",
        account_name: null,
        is_debit_account: null,
      });
    },

    openMappingModal(data) {
      this.dataset = data.id;
      let findAccount = [];

      if (this.product) {
        findAccount = this.allMappedAccounts.filter(
          (acc) => acc.dataset === this.dataset &&
            (acc.product_id === this.product.id || acc.product_id === this.product.loanProductKey)
        );
      } else {
        findAccount = this.allMappedAccounts.filter(
          (acc) => acc.dataset === this.dataset
        );
      }
      // const findAccount = this.allMappedAccounts.filter(
      //   (acc) => acc.dataset === this.dataset
      // );
      this.showDatasetModal = true;
      this.getSingleAccountData = findAccount;
    },

    addCommas(amount) {
      let currency = `\u20A6`;
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return `${currency}${fixedAmount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      } else {
        return `${currency}0.00`;
      }
    },

    getProductName(id) {
      let prodName = "";

      if (id) {
        prodName = this.allProducts.find(
          (item) => item.id === id || item.loanProductKey === id
        )?.productName;
      }

      return prodName;
    },

    getAllProductForDataset(data) {
      const findAccount = this.allMappedAccounts.find(
        (acc) => acc.dataset === data.id
      );
      let prodName = "";

      if (findAccount && findAccount.product_id) {
        prodName = this.allProducts.find(
          (item) =>
            item.id === findAccount.product_id ||
            item.loanProductKey === findAccount.product_id
        )?.productName;
      }

      return prodName;
    },

    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.parameters.pageindex++;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages === "previous") {
        this.parameters.pageindex--;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages !== null) {
        this.parameters.pageindex = pages;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      }
    },

    changeLength(value) {
      this.parameters.pagesize = value;
      this.$emit("getTransData", this.parameters);
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  computed: {
    setLoading() {
      return this.loading;
    },
  },
};
</script>

<style scoped>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 23%;
  text-align: flex-start;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 23%;
  text-align: flex-start;
  padding: 10px 40px 10px 0;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
